// https://www.w3.org/TR/html4/sgml/entities.html

const entities = new Map([
  /* General Punctuation */
  ['&#8211;', '-'],
  ['&#8212;', '–'],
  ['&#8216;', "'"],
  ['&#8217;', "'"],
  ['&#8220;', '“'],
  ['&#8221;', '”'],
  ['&#038;', '&'],
  ['&amp;', '&'],
])

const reCache = new Map([])

export const replaceEntities = str => {
  let result = `${str}`
  entities.forEach((value, key) => {
    if (!reCache.has(key)) {
      reCache.set(key, new RegExp(key, 'g'))
    }
    const re = reCache.get(key)
    result = result.replace(re, value)
  })
  return result
}

export const stripHTML = html => {
  const tmp = document.createElement('SPAN')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

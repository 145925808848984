import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {AnimatePresence} from 'framer-motion'
import {ThemeProvider, ColorPresenceProvider} from 'core/theme'
import {Loader, NavBarConnected, FooterCompact, CookieConsent} from 'modules'
import {LanguageProvider, LanguageContext} from 'core/language'
// import ScrollRestore from 'pages/components/ScrollRestore'
import ScrollToTop from 'pages/components/ScrollToTop'
import RouteConfig from 'core/RouteConfig'

function HelmetWithLang() {
  const {activeLang} = React.useContext(LanguageContext)
  const title =
    activeLang === 'ru'
      ? 'Официальный сайт Food Union'
      : 'Food Union official website '
  const description =
    activeLang === 'ru'
      ? 'Пионер молочной промышленности с 1909 года'
      : 'The leading role of the dairy industry since 1909'
  return (
    <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <meta name='description' content={description} />
    </Helmet>
  )
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        {/* FIXME: Problems with content layout and
            section offset, background color presence.
          <ScrollRestore />
        */}

        <Helmet
          defaultTitle='Food Union Official Website'
          titleTemplate='%s | Food Union Official Website'
        >
          <meta
            name='description'
            content='Pioneering the dairy industry since 1909'
          />
        </Helmet>

        <LanguageProvider>
          <ColorPresenceProvider>
            <NavBarConnected />
            <HelmetWithLang />

            <React.Suspense fallback={<Loader />}>
              <Route
                render={({location}) => (
                  <>
                    <ScrollToTop />

                    <AnimatePresence
                      exitBeforeEnter
                      // onExitComplete={() => {
                      //   window.scrollTo(0, 0)
                      // }}
                    >
                      <RouteConfig location={location} />
                    </AnimatePresence>
                  </>
                )}
              />
            </React.Suspense>

            <FooterCompact />
            <CookieConsent />
          </ColorPresenceProvider>
        </LanguageProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App

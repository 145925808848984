import React from 'react'
import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {media, scale} from 'core/theme'

const size = [140, 220]

const MotionList = styled(motion.ul)`
  flex-direction: row;
  margin: ${rem(-scale[1])};
  padding-bottom: 1rem;
  max-width: 100%;

  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overflow-y: hidden;

  @media (pointer: fine) {
    ::-webkit-scrollbar {
      border-radius: 2px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #dedede;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }

  &:empty {
    min-height: ${rem(size[0])};
  }

  > li {
    padding: ${rem(scale[1])};
  }

  ${({scroll}) =>
    scroll === false &&
    css`
      flex-wrap: wrap;
      justify-content: center;

      > li {
        flex: 0;
      }
    `};

  ${media.tablet`
    flex-wrap: wrap;
    justify-content: center;
    margin: ${rem(-scale[2])};
    padding-bottom: 0;
    overflow: visible;

    &:empty {
      min-height: ${rem(size[1])};
    }

    > li {
      padding: ${rem(scale[2])};
    }
  `};
`

const MotionItem = styled(motion.li)`
  flex: 1;
  scroll-snap-align: start;
`

const listVariants = {
  enter: transition => ({transition: {staggerChildren: 0.05, ...transition}}),
}

const itemVariants = {
  initial: {y: 50, opacity: 0},
  enter: {
    y: 0,
    opacity: 1,
    transition: {type: 'spring', damping: 15, stiffness: 150},
  },
}

export default function ItemList({
  items = [],
  component,
  componentProps = {},
  transition = {},
  scroll,
}) {
  return (
    <MotionList
      custom={transition}
      exit='exit'
      variants={listVariants}
      scroll={scroll}
    >
      {items.map((props, i) => {
        const el = React.createElement(component, {
          ...props,
          ...componentProps,
          custom: {index: i},
        })
        return (
          <MotionItem key={`item_${i + 1}`} variants={itemVariants}>
            {el}
          </MotionItem>
        )
      })}
    </MotionList>
  )
}

import React from 'react'
import ProductContext from 'services/products'
import {linkTableRecords} from '../../pages/components/products/helpers'

function reducer(state, action) {
  switch (action.type) {
    case 'setRecordsInTable':
      const {table, records} = action.payload
      return {...state, [table]: records}

    case 'extendLinkedRecords':
      const {table: extendTable, records: extendedRecords} = action.payload
      return {...state, [extendTable]: extendedRecords}

    case 'linkTableRecords':
      const nonEmptyRecords = action.payload.records.filter(record =>
        record.fields.hasOwnProperty('Name')
      )
      const combinedData = linkTableRecords(
        state['_'] || state['Products'] || [],
        {
          fieldName: action.payload.table,
          linkedTable: nonEmptyRecords,
        }
      )
      return {...state, _: combinedData}

    case 'setAllProductsData':
      return {...action.payload}

    default:
      throw new Error()
  }
}

export default function ProductService({children}) {
  const [state, dispatch] = React.useReducer(reducer, {}, () => {
    // FIXME: re-inicializācijas quick-fix - glabājam visu sessionStorage
    const value = sessionStorage.getItem('__catalogue_data')
    if (value) {
      const data = JSON.parse(value)
      return data
    }
    return {}
  })

  const contextValue = React.useMemo(() => {
    return [state, dispatch]
  }, [state, dispatch])

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  )
}

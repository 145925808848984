import React from 'react'
import ProductContext from 'services/products'

const tables = [
  '__',
  'Products',
  'Brands',
  'Categories',
  'Subcategories',
  'Business direction',
  'Custom filters',
  'Numeric filters',
  'Translations',
]

export default function useAllProductsData() {
  const [state, dispatch] = React.useContext(ProductContext)
  // const [error, setError] = React.useState()
  const status = React.useRef(null)

  React.useEffect(() => {
    if (!status.current) {
      status.current = 'pending'
      const promises = tables.map(table =>
        fetch(`/api/products/${encodeURIComponent(table)}`)
      )
      Promise.all(promises)
        .then(res => res.map(res => res.json()))
        .then(json => Promise.all(json))
        .then(data => {
          const result = {}
          tables.forEach((key, i) => {
            result[key] = data[i]
          })
          status.current = 'success'
          dispatch({
            type: 'setAllProductsData',
            payload: result,
          })

          // FIXME: re-inicializācijas quick-fix - glabājam visu sessionStorage
          sessionStorage.setItem('__catalogue_data', JSON.stringify(result))
        })
    }
  }, [dispatch])

  return state
}

export function useCombinedProducts() {
  const [state] = React.useContext(ProductContext)

  useAllProductsData()

  return {
    allProducts: state['__'] || [],
    brands: state['Brands'] || [],
    categories: state['Categories'] || [],
    subcategories: state['Subcategories'] || [],
    businessDirection: state['Business direction'] || [],
    features: state['Custom filters'] || [],
    numericFilters: state['Numeric filters'] || [],
    translations: state['Translations'] || [],
  }
}

import getSlug from 'speakingurl'

export function getProductSlug(productName, lang = 'en') {
  let percentage = 'percentage'
  if (lang === 'ru') {
    percentage = 'procentov'
  }

  let slug = getSlug(productName, {lang, custom: {'%': `-${percentage}`}})
  slug = slug.replace(/(\d)([ml,l,g,kg])/g, '$1-$2')

  return slug
}

export function linkTableRecords(data, {fieldName, linkedTable}) {
  const extendedRecords = data.map(record => {
    const ids = record.fields[fieldName]
    if (ids) {
      const linkedRecords = ids.map(id => linkedTable.find(r => r.id === id))
      const newFields = {...record.fields, [fieldName]: linkedRecords}
      return {...record, fields: newFields}
    }
    return record
  })
  return extendedRecords
}

// https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72
// https://gist.github.com/michalvichy/9647bd41b6dba147a0068149e2b4961a
export function filterArray(array, filters) {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // Will match at least one (inclusive) filter criteria.
    // Use every() to match all (esclusive) filter criteria.
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true
      return filters[key](item)
    })
  })
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

// https://www.peterbe.com/plog/a-darn-good-search-filter-function-in-javascript
export function getSearchRegex(words) {
  return new RegExp(
    words
      .map(word => `(?=.*${escapeRegExp(word)})`)
      .join('')
      .concat('.+'),
    'gi'
  )
}

export function findRelatedProducts(related, products, maxCount = 3) {
  const relatedCategory =
    related.fields['Categories'] && related.fields['Categories'][0]
  if (relatedCategory) {
    let result = []
    const n = products.length
    for (let i = 0; i < n; i++) {
      const product = products[i]
      const category =
        product.fields['Categories'] && product.fields['Categories'][0]
      if (
        category &&
        category.id === relatedCategory.id &&
        product.id !== related.id
      ) {
        result.push(product)
      }
      if (result.length === maxCount) {
        break
      }
    }

    return result
  }

  return []
}

// Storage, Weight filter range helpers

const largerThanRe = />\s?(\d+)/
const smallerThanRe = /<\s?(\d+)/
const rangeRe = /(\d+)\s?[-–]\s?(\d+)/

export const parseFilterRangeValue = value => {
  // match "> 1000"
  if (largerThanRe.test(value)) {
    const [, v] = largerThanRe.exec(value)
    return [parseInt(v), Number.MAX_SAFE_INTEGER]
  }
  // match "< 15"
  else if (smallerThanRe.test(value)) {
    const [, v] = smallerThanRe.exec(value)
    return [0, parseInt(v)]
  }
  // match "16-30"
  else if (rangeRe.test(value)) {
    const [, min, max] = rangeRe.exec(value)
    const negative = value.startsWith('-')
    const minInt = parseInt(min)
    const maxInt = parseInt(max)
    return negative ? [maxInt, minInt] : [minInt, maxInt]
  }
}

export const valueInFilterRange = (fieldValue, range) => {
  const [min, max] = range
  const valueInt = parseInt(fieldValue)
  return valueInt >= min && valueInt <= max
}

// Multilanguage routing helpers

export function getLangFromPathname(
  pathname,
  defaultLang = 'en',
  availableLangs = ['en', 'ru']
) {
  const level1 = pathname.split('/')
  const lang =
    level1[1] !== defaultLang && availableLangs.includes(level1[1])
      ? level1[1]
      : defaultLang
  return {lang, defaultLang}
}

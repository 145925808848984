import React from 'react'
import Cookies from 'js-cookie'

const COOKIE_EXPIRES = 5
const COOKIE_NAME = 'products-wishlist'

export default function useProductWishlist() {
  const [wishlist, setWishlist] = React.useState(() => {
    const cookieValue = Cookies.get(COOKIE_NAME)
    if (cookieValue) {
      try {
        const data = JSON.parse(cookieValue)
        return data
      } catch (error) {}
    }
    return []
  })

  const productWish = React.useCallback(
    (product, wish) => {
      const data = new Set(wishlist)
      if (wish) {
        data.add(product.id)
      } else {
        data.delete(product.id)
      }
      const array = [...data]
      setWishlist(array)
      try {
        const cookieValue = JSON.stringify(array)
        Cookies.set(COOKIE_NAME, cookieValue, {expires: COOKIE_EXPIRES})
      } catch (errro) {}
    },
    [wishlist]
  )

  function resetWishlist() {
    Cookies.remove(COOKIE_NAME)
    setWishlist([])
  }

  function wishlistIncludesProduct(product) {
    return wishlist.includes(product.id)
  }

  return {
    wishlist,
    productWish,
    resetWishlist,
    wishlistIncludesProduct,
  }
}

import React from 'react'

const fetchData = (url, signal, setState) => {
  fetch(url, {signal})
    .then(res =>
      res.ok
        ? res
        : Promise.reject({message: res.statusText, status: res.status})
    )
    .then(res => res.json())
    .then(data => {
      setState(prevState => ({
        ...prevState,
        data,
        loading: false,
      }))
    })
    .catch(error => {
      setState(prevState => ({
        ...prevState,
        error: error.name !== 'AbortError' && error,
        loading: false,
      }))
    })
}

export default function useAbortableFetch(url) {
  const [state, setState] = React.useState({
    data: null,
    error: false,
    loading: false,
    controller: null,
  })

  const isMounted = React.useRef(false)

  React.useLayoutEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    const controller = new AbortController()

    setState(prevState => ({
      ...prevState,
      loading: true,
      controller,
    }))

    fetchData(url, controller.signal, state => {
      if (isMounted.current) {
        setState(state)
      }
    })

    return () => controller.abort()
  }, [url])

  return {
    data: state.data,
    error: state.error,
    loading: state.loading,
    abort: () => state.controller && state.controller.abort(),
  }
}

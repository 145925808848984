import React from 'react'
import styled from 'styled-components/macro'
import {motion} from 'framer-motion'
import {Graphic} from 'modules/Article/elements'

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 50vh;
  width: 100%;
`

const Wrapper = styled(motion.div)`
  position: relative;
  width: 120px;
  height: 120px;
  overflow: visible;

  svg {
    overflow: visible;
  }
`

const Effect = styled.div`
  width: 240px;
  height: 240px;
  filter: url('#goo');

  transform: scale(0.5);
  transform-origin: top left;
`

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 60px;
    height: 60px;

    circle {
      fill: transparent;
    }

    path {
      stroke: #ffffff;
    }
  }
`

/* TODO: Loader animation: https://www.uplabs.com/posts/shopping-loader-gif-animation */

export default function Loader() {
  const [icon] = React.useState(() => {
    const icons = [
      'champion-cup',
      'glasses-cheers',
      'heart',
      'ice-cream',
      'milk-can',
      'plant',
      'treasure',
    ]
    function getRandomInt(max, min = 0) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min)) + min
    }
    const randomIcon = icons[getRandomInt(icons.length)]
    return randomIcon
  })

  return (
    <Container>
      <Wrapper
        initial={{opacity: 0, y: 50}}
        animate={{opacity: 1, y: 0, transition: {delay: 0.5}}}
        exit='exit'
      >
        <Effect>
          <svg
            width='240'
            height='240'
            viewBox='0 0 240 240'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <motion.circle
              cx='35'
              cy='35'
              r='28'
              fill='#31589F'
              style={{originX: '30%', originY: '30%'}}
              initial={{rotate: 180}}
              animate={{rotate: [180, 360, 540, 540, 540, 540, 540, 540]}}
              transition={{loop: 'Infinity', duration: 3}}
              exit='exit'
            />
            <motion.circle
              cx='35'
              cy='205'
              r='28'
              fill='#54B1D0'
              style={{originX: '30%', originY: '70%'}}
              initial={{rotate: 180}}
              animate={{rotate: [180, 180, 360, 540, 540, 540, 540, 540]}}
              transition={{loop: 'Infinity', duration: 3, delay: 0.35}}
              exit='exit'
            />
            <motion.circle
              cx='205'
              cy='205'
              r='28'
              fill='#ACCE42'
              style={{originX: '70%', originY: '70%'}}
              initial={{rotate: 180}}
              animate={{rotate: [180, 180, 180, 360, 540, 540, 540, 540]}}
              transition={{loop: 'Infinity', duration: 3, delay: 0.7}}
              exit='exit'
            />
            <motion.circle
              cx='205'
              cy='35'
              r='28'
              fill='#F7D548'
              style={{originX: '70%', originY: '30%'}}
              initial={{rotate: 180}}
              animate={{rotate: [180, 180, 180, 180, 360, 540, 540, 540]}}
              transition={{loop: 'Infinity', duration: 3, delay: 1.05}}
              exit='exit'
            />
            <motion.circle
              cx='120'
              cy='120'
              r='50'
              fill='#503578'
              animate={{
                fill: [
                  '#31589F',

                  '#54B1D0',
                  '#54B1D0',
                  '#ACCE42',
                  '#ACCE42',
                  '#F7D548',
                  '#F7D548',

                  '#31589F',
                ],
              }}
              transition={{loop: 'Infinity', duration: 3, delay: 0.35}}
              exit='exit'
            />
          </svg>

          <svg xmlns='http://www.w3.org/2000/svg'>
            <defs>
              <filter id='goo'>
                <feGaussianBlur
                  in='SourceGraphic'
                  stdDeviation='20'
                  result='blur'
                />
                <feColorMatrix
                  in='blur'
                  values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -8'
                  result='goo'
                />
              </filter>
            </defs>
          </svg>
        </Effect>

        <Icon>
          <Graphic icon={icon} />
        </Icon>
      </Wrapper>
    </Container>
  )
}

import React from 'react'
import {motion} from 'framer-motion'
import {ExternalLink} from 'elements'
import {Icon} from './'

const iconVariants = {
  initial: {
    y: 0,
  },
  enter: {
    y: -5,
    transition: {type: 'spring', stiffness: 200},
  },
}

export default function PlatformLink({id, url, title, linkLabel}) {
  const [isHover, setIsHover] = React.useState(false)
  if (linkLabel) {
    return (
      <ExternalLink
        url={url}
        title={title}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Icon
          id={id}
          as={motion.i}
          animate={isHover ? 'enter' : 'initial'}
          variants={iconVariants}
        />
        {linkLabel && <span>{linkLabel}</span>}
      </ExternalLink>
    )
  }
  return (
    <ExternalLink url={url} title={title}>
      <Icon id={id} as={motion.i} whileHover={iconVariants.enter} />
      {linkLabel && <span>{linkLabel}</span>}
    </ExternalLink>
  )
}

import React from 'react'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {color, duration, ease, media, scale} from 'core/theme'
import {ReactComponent as Arrow} from 'icons/assets/arrow.svg'
import {ExternalLink} from 'elements'

export const styleBase = css`
  border: 1px solid ${color.text.default};
  color: ${color.text.default};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem(15)};
  margin: 0;
  padding: ${rem(scale[2])} ${rem(scale[4])};
  text-decoration: none;

  transition-property: background-color, color;
  transition-duration: ${duration.normal.out};
  transition-timing-function: ${ease.out};

  i {
    display: inline-block;
    margin-left: ${rem(scale[2])};
    transform: translateY(1px) scale(-1);
    transition-property: transform;
    transition-duration: ${duration.normal.out};
    transition-timing-function: ${ease.out};
  }

  &:hover {
    background-color: ${color.text.default};
    color: ${color.white};

    transition-duration: ${duration.normal.in};
    transition-timing-function: ${ease.in};

    i {
      transform: translateX(100%) translateY(1px) scale(-1);
      transition-property: transform;
      transition-duration: ${duration.normal.in};
      transition-timing-function: ${ease.in};
    }
  }

  ${media.tablet`
    font-size: ${rem(18)};
    padding: ${rem(scale[2])} ${rem(scale[10])};
  `};
`

const StyledExternalLink = styled(ExternalLink)`
  ${styleBase};

  &:hover {
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  ${styleBase};
`

export default function ActionLink({to, label, showArrow, className, style}) {
  const isExternal = typeof to === 'string' && to.startsWith('http')
  return isExternal ? (
    <StyledExternalLink url={to} className={className} style={style}>
      {label}
      {showArrow && (
        <i>
          <Arrow />
        </i>
      )}
    </StyledExternalLink>
  ) : (
    <StyledLink to={to} className={className} style={style}>
      {label}
      {showArrow && (
        <i>
          <Arrow />
        </i>
      )}
    </StyledLink>
  )
}

import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {
  AnimatePresence,
  motion,
  useSpring,
  useTransform,
  useViewportScroll,
} from 'framer-motion'
import {useMatchMedia} from 'core/hooks'
import {useTranslation} from 'core/language'
import {
  ColorPresence,
  breakpoints,
  media,
  duration,
  ease,
  scale,
} from 'core/theme'
import {ContentWidth} from 'elements'
import Logo from './components/Logo'
import MainNav from './components/MainNav'
import MobileNav from './components/MobileNav'
import MobileToggle from './components/MobileToggle'
import LangNav from './components/LangNav'

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  min-height: ${rem(scale[6])};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  ${media.tablet`
    min-height: ${rem(scale[9])};
  `};
`

const BackgroundColor = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${rem(-scale[1])};
`

export const Column = styled.div`
  margin: 0 ${rem(scale[1])};
`

const NavGroup = styled.div`
  display: flex;
  align-items: center;
`

const HomeLink = styled(Link)`
  box-sizing: content-box;
  display: block;
  width: ${rem(72)};

  ${media.tablet`
    width: ${rem(82)};
  `};

  svg {
    width: 100%;
  }
`

const Pusher = styled.div`
  padding-top: ${rem(59)};

  ${media.tablet`
    padding-top: ${rem(81)};
  `};
`

const WishlistLinkWrapper = styled(motion.div)`
  margin-left: ${rem(scale[2])};
  transform: translateY(1px);

  > a {
    color: currentColor;
    display: block;
    padding: 0.75em;
    transition: transform ${duration.fast.in} ${ease.out};
    will-change: transform;

    &:hover {
      transform: scale(1.2);
    }
  }
`

function WishlistLink({link, translate, saturation}) {
  const textColor = useTransform(saturation, [0, 1], ['#31589F', '#ffffff'])
  return (
    <WishlistLinkWrapper style={{color: textColor}}>
      <Link to={link} title={translate('Wishlist')}>
        <svg
          width='22'
          height='20'
          viewBox='0 0 22 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M20.9999 3.09297C20.1066 1.25964 17.5332 -0.24036 14.5399 0.632973C13.1096 1.04613 11.8616 1.93216 10.9999 3.14631C10.1382 1.93216 8.89027 1.04613 7.45991 0.632973C4.45991 -0.227027 1.89324 1.25964 0.999911 3.09297C-0.253422 5.65964 0.266578 8.54631 2.54658 11.673C4.33325 14.1196 6.88658 16.5996 10.5932 19.4796C10.7104 19.571 10.8547 19.6206 11.0032 19.6206C11.1518 19.6206 11.2961 19.571 11.4132 19.4796C15.1132 16.6063 17.6732 14.1463 19.4599 11.673C21.7332 8.54631 22.2532 5.65964 20.9999 3.09297Z'
            fill='currentColor'
          />
        </svg>
      </Link>
    </WishlistLinkWrapper>
  )
}

export default function NavBar({
  activeLang,
  defaultLang,
  homeLink,
  langNav,
  pages,
  wishlistLink,
}) {
  const {color, saturation} = React.useContext(ColorPresence)
  const prevPresenceRef = React.useRef()

  const matchLaptop = useMatchMedia(breakpoints.laptop)
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false)

  const {scrollY} = useViewportScroll()
  const backgroundOpacity = useSpring(0, {
    damping: 40,
    stiffness: 150,
  })
  const backgroundOpacityTarget = React.useRef(0)

  React.useEffect(
    () =>
      scrollY.onChange(latest => {
        if (latest > 100 && backgroundOpacityTarget.current !== 1) {
          backgroundOpacityTarget.current = 1
          backgroundOpacity.set(1)
        } else if (latest <= 100 && backgroundOpacityTarget.current !== 0) {
          backgroundOpacityTarget.current = 0
          backgroundOpacity.set(0)
        }
      }),
    [scrollY, backgroundOpacity]
  )

  React.useEffect(() => {
    document.body.classList.toggle('disable-scroll', mobileNavOpen)
  }, [mobileNavOpen])

  const translate = useTranslation()

  return (
    <>
      <StyledHeader>
        <BackgroundColor
          style={{
            backgroundColor: color,
            opacity: backgroundOpacity,
          }}
        />

        <ContentWidth>
          <Flex>
            <Column>
              <HomeLink
                to={homeLink}
                title={translate('Go to home page')}
                onClick={() => {
                  backgroundOpacityTarget.current = 0
                  backgroundOpacity.set(0)
                  setMobileNavOpen(false)
                }}
              >
                <Logo />
              </HomeLink>
            </Column>

            <Column>
              {matchLaptop ? (
                <NavGroup>
                  <MainNav pages={pages} />
                  <LangNav
                    activeLang={activeLang}
                    defaultLang={defaultLang}
                    to={langNav}
                  />
                  {wishlistLink && (
                    <WishlistLink
                      link={wishlistLink}
                      translate={translate}
                      saturation={saturation}
                    />
                  )}
                </NavGroup>
              ) : (
                <NavGroup>
                  <MobileToggle
                    isOpen={mobileNavOpen}
                    onToggle={() => {
                      if (mobileNavOpen) {
                        const {
                          colorValue,
                          saturationValue,
                        } = prevPresenceRef.current
                        color.set(colorValue)
                        saturation.set(saturationValue)
                      } else {
                        prevPresenceRef.current = {
                          colorValue: color.get(),
                          saturationValue: saturation.get(),
                        }
                        color.set('#FFFFFF')
                        saturation.set(0)
                      }
                      backgroundOpacityTarget.current = mobileNavOpen ? 1 : 0
                      backgroundOpacity.set(backgroundOpacityTarget.current)
                      setMobileNavOpen(!mobileNavOpen)
                    }}
                  />
                  <LangNav
                    activeLang={activeLang}
                    defaultLang={defaultLang}
                    to={langNav}
                  />
                </NavGroup>
              )}
            </Column>
          </Flex>
        </ContentWidth>
      </StyledHeader>

      <AnimatePresence initial={false}>
        {mobileNavOpen && (
          <MobileNav
            color={color}
            pages={pages}
            onNavigate={() => setMobileNavOpen(false)}
            wishlistLink={wishlistLink}
            translate={translate}
          />
        )}
      </AnimatePresence>

      <Pusher />
    </>
  )
}

import React from 'react'

const DEBUG = false

export default function useAnalytics(props, type = 'pageView', deps = []) {
  React.useEffect(() => {
    if (DEBUG && props && process.env.NODE_ENV !== 'production') {
      console.log('analytics', type, props)
    } else if (process.env.REACT_APP_GTAG_ENABLED && props && window.gtag) {
      switch (type) {
        case 'events':
          const {action, category, label} = props
          window.gtag('event', action, {
            event_category: category,
            event_label: label,
          })
          break

        case 'timings':
          const {name, value, category: timingsCategory} = props
          window.gtag('event', 'timing_complete', {
            name: name,
            value: value,
            event_category: timingsCategory,
          })
          break

        case 'pageView':
          const {title, path} = props
          window.gtag('config', process.env.REACT_APP_GTAG_TRACKING_ID, {
            page_title: title,
            page_path: path,
          })
          break

        default:
          console.warn('analytics', `unknown type '${type}'`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

import React from 'react'
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
  css,
} from 'styled-components/macro'
import WebFont from 'webfontloader'
import {rem} from 'polished'
import {color} from './tokens/color'

const reset = css`
  a {
    padding: 0.5em;
  }

  a,
  img {
    display: inline-block;
  }

  button {
    appearance: none;
    background-color: transparent;
    border: none;
    font: inherit;
  }

  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;

    display: flex;
    flex-direction: column;
  }

  svg {
    display: block;
  }
`

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    color: ${color.text.default};
    font-family: 'Noto Sans', sans-serif;
    font-size: ${rem(18)};
    margin: 0;
    overflow-x: hidden;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-tap-highlight-color: transparent;

    &.disable-scroll {
      overflow: hidden;
    }
  }

  ::selection {
    color: ${color.white};
    background-color: ${color.text.default};
  }

  ${reset};

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
      flex: 1;
    }
  }
`

const defaultTheme = {}

export default function ThemeProvider({children}) {
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Noto+Sans:400,400i,700,700i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext',
        ],
      },
    })
  }, [])

  return (
    <>
      <GlobalStyle />
      <StyledThemeProvider theme={defaultTheme}>{children}</StyledThemeProvider>
    </>
  )
}

import React from 'react'
import {useSpring} from 'framer-motion'

export const ColorPresence = React.createContext()

export function ColorPresenceProvider({children}) {
  const color = useSpring('#ffffff', {damping: 15})
  const saturation = useSpring(0, {damping: 15})
  return (
    <ColorPresence.Provider value={{color, saturation}}>
      {children}
    </ColorPresence.Provider>
  )
}

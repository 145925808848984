import React from 'react'
import {Helmet} from 'react-helmet'
import {Switch, Route} from 'react-router-dom'
import {useViewportScroll} from 'framer-motion'
import {LanguageContext, useTranslation} from 'core/language'
import {useAnalytics} from 'core/hooks'
import {ProductService} from 'services/products'

const Home = React.lazy(() => import('pages/Home'))
const NotFound = React.lazy(() => import('pages/NotFound'))

const Export = React.lazy(() => import('pages/Export'))
const ExportCatalogue = React.lazy(() => import('pages/ExportCatalogue'))
const ProductWishlist = React.lazy(() => import('pages/ProductWishlist'))
const ProductWishlistForm = React.lazy(() =>
  import('pages/ProductWishlistForm')
)

function RouteWithSubRoutes(route) {
  useAnalytics(
    {
      title: route.page.title,
      path: route.location.pathname,
    },
    'pageView',
    [route.page.title, route.location.pathname]
  )

  const {scrollYProgress} = useViewportScroll()
  React.useEffect(() => {
    return () => {
      let DEBUG_ANALYTICS = false
      const latestScrollYProgress = scrollYProgress.get()

      if (process.env.REACT_APP_GTAG_ENABLED === 'true') {
        // Page is scrolled more than one screen in height
        if (window.pageYOffset > window.innerHeight) {
          window.gtag &&
            window.gtag('event', 'scroll', {
              event_category: 'engagement',
              event_label: 'scroll_progress',
              event_value: Math.round(latestScrollYProgress * 100) / 100,
            })
        }
      } else if (DEBUG_ANALYTICS) {
        // Page is scrolled more than one screen in height
        if (window.pageYOffset > window.innerHeight) {
          console.log('analytics', 'scroll', {
            category: 'engagement',
            label: 'scroll_progress',
            value: Math.round(latestScrollYProgress * 100) / 100,
          })
        }
      }
    }
  }, [scrollYProgress])

  const {page, activeLang} = route
  const [pageData, setPageData] = React.useState()
  React.useEffect(() => {
    if (page) {
      fetch(`/api/pages/${page.slug}/${activeLang}`)
        .then(res => res.json())
        .then(setPageData)
    }
  }, [page, activeLang])

  return (
    <Route
      path={route.path}
      render={props => (
        <>
          {pageData && (
            <Helmet>
              {pageData[0] && pageData[0].title && (
                <title>{pageData[0].title}</title>
              )}
              {pageData[0] && pageData[0].excerpt && (
                <meta name='description' content={pageData[0].excerpt} />
              )}
            </Helmet>
          )}

          <route.component
            {...props}
            page={route.page}
            subpages={route.subpages}
            routes={route.routes}
          />
        </>
      )}
    />
  )
}

export default function RouteConfig({location}) {
  const {activeLang} = React.useContext(LanguageContext)
  const translate = useTranslation()

  // const {data} = useAbortableFetch(`/api/all-pages/${activeLang}`)

  const [data, setData] = React.useState()
  React.useEffect(() => {
    if (location.state && location.state.pageData) {
      setData(location.state.pageData)
    } else {
      fetch(`/api/all-pages/${activeLang}`)
        .then(res => res.json())
        .then(pageData => {
          setData(pageData)
        })
    }
  }, [activeLang, location.state])

  const routes = React.useMemo(() => {
    if (!data) {
      return []
    }

    function getTemplateNameBySlug(slug) {
      switch (slug) {
        case 'news':
        case 'novosti':
          return 'News'

        case 'products':
        case 'produkty':
          return 'Products'

        case 'brands':
        case 'brendi':
          return 'Brands'

        case 'companies':
        case 'kompanii':
          return 'Companies'

        case 'quality':
        case 'kachestvo':
        case 'trendtrack':
        case 'trendtrek':
          return 'ColorPage'

        // case 'vacancy':
        // case 'vakansii':
        //   return 'Vacancy'

        case 'about-us':
        case 'o-nas':
          // case 'kas-mes-esam':
          // case 'kto-my':
          // case 'par-ko-mes-rupejamies':
          // case 'o-chem-my-zabotimsya':
          return 'AboutUs'

        case 'contacts':
        case 'kontakty':
          return 'Contacts'

        case 'media-kit':
        case 'dlya-smi':
          return 'MediaKit'

        case 'privacy-policy':
        case 'politika-konfidentsialnosti':
          return 'PrivacyPolicy'

        default:
          return null
      }
    }

    let pages = data
      .sort((a, b) => b.parent - a.parent)
      .map(page => {
        const templateName = getTemplateNameBySlug(page.slug)
        if (templateName) {
          if (templateName === 'News') {
            return [
              {
                page,
                path: `${page.url}/:slug`,
                component: React.lazy(() => import(`pages/NewsPost`)),
              },
              {
                page,
                path: page.url,
                component: React.lazy(() => import(`pages/News`)),
              },
            ]
          } else if (templateName === 'Brands') {
            return [
              {
                page,
                path: `${page.url}/:slug`,
                component: React.lazy(() => import(`pages/BrandsPost`)),
              },
              {
                page,
                path: page.url,
                component: React.lazy(() => import(`pages/Brands`)),
              },
            ]
          } else if (templateName === 'Companies') {
            return [
              {
                page,
                path: `${page.url}/:slug`,
                component: React.lazy(() => import(`pages/CompaniesPost`)),
              },
              {
                page,
                path: page.url,
                component: React.lazy(() => import(`pages/Companies`)),
              },
            ]
          } else if (templateName === 'AboutUs') {
            const subpages = data.filter(p => p.parent !== 0)
            return {
              page,
              subpages,
              path: page.url,
              component: React.lazy(() => import(`pages/${templateName}`)),
            }
          }
          //   else if (templateName === 'Vacancy') {
          //     return [
          //       {
          //         page,
          //         path: `${page.url}/:slug`,
          //         component: React.lazy(() => import(`pages/VacancyPost`)),
          //       },
          //       {
          //         page,
          //         path: page.url,
          //         component: React.lazy(() => import(`pages/Vacancy`)),
          //       },
          //     ]
          //   }
          else if (templateName === 'Contacts') {
            return [
              {
                page,
                path: `${page.url}/:country`,
                component: React.lazy(() => import(`pages/ContactsCountry`)),
              },
              {
                page,
                path: page.url,
                component: React.lazy(() => import(`pages/Contacts`)),
              },
            ]
          } else {
            return {
              page,
              path: page.url,
              component: React.lazy(() => import(`pages/${templateName}`)),
            }
          }
        }
        return null
      })

    pages = pages
      .filter(page => page !== null)
      .concat({
        key: 'Home',
        path: '/ru',
        exact: true,
        component: Home,
        page: {
          title: translate('Homepage'),
          slug: 'glavnaya-stranitsа',
        },
      })
      .concat({
        key: 'Home',
        path: '/',
        exact: true,
        component: Home,
        page: {
          title: translate('Homepage'),
          slug: 'home',
        },
      })
      .concat({
        key: '404',
        path: undefined,
        component: NotFound,
        page: {
          title: translate('Not found'),
          slug: '404',
        },
      })
      .flat()

    const routes = pages.map(page => (
      <RouteWithSubRoutes key={page.path} activeLang={activeLang} {...page} />
    ))

    return routes
  }, [data, translate, activeLang])

  return (
    <Switch location={location} key={location.pathname}>
      <Route
        path='/export/catalogue/wishlist/form'
        render={props => {
          return (
            <ProductService>
              <ProductWishlistForm {...props} />
            </ProductService>
          )
        }}
      />
      <Route
        path='/export/catalogue/wishlist'
        render={props => {
          return (
            <ProductService>
              <ProductWishlist {...props} />
            </ProductService>
          )
        }}
      />
      <Route
        path='/export/catalogue'
        render={props => {
          return (
            <ProductService>
              <ExportCatalogue {...props} />
            </ProductService>
          )
        }}
      />
      <Route path='/export' component={Export} />

      <Route
        path='/ru/eksport/katalog/spisok-zhelaniy/forma'
        render={props => {
          return (
            <ProductService>
              <ProductWishlistForm {...props} />
            </ProductService>
          )
        }}
      />
      <Route
        path='/ru/eksport/katalog/spisok-zhelaniy'
        render={props => {
          return (
            <ProductService>
              <ProductWishlist {...props} />
            </ProductService>
          )
        }}
      />
      <Route
        path='/ru/eksport/katalog'
        render={props => {
          return (
            <ProductService>
              <ExportCatalogue {...props} />
            </ProductService>
          )
        }}
      />
      <Route path='/ru/eksport' component={Export} />

      {routes}
    </Switch>
  )
}

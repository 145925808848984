import React from 'react'
import {useViewportScroll, useTransform} from 'framer-motion'
import debounce from 'lodash.debounce'

export default function useScrollYProgress(
  ref,
  onProgressChange,
  {trigger = 0} = {}
) {
  const {scrollY} = useViewportScroll()
  const [elementBounds, setElementBounds] = React.useState({top: 0, bottom: 0})

  const elementTop = elementBounds.top
  const elementBottom = elementBounds.bottom

  // const key = ref.current && ref.current.getAttribute('data-key')

  const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, -1], {
    clamp: false,
  })

  // NOTE: Changed behaviout from section progress to relative scrollY
  // const yProgress = useTransform(scrollY, [elementTop, elementBottom], [0, 1])
  const yRelative = useTransform(
    scrollY,
    [elementTop, elementBottom],
    [0, elementBottom - elementTop]
  )

  // const [scrollYDetails, setScrollYDetails] = React.useState({
  //   direction: 1,
  //   progress: 0,
  // })

  // React.useEffect(
  //   () =>
  //     yProgress.onChange(progress => {
  //       const delta = y.current - y.prev
  //       const direction = delta > 0 ? -1 : delta < 0 ? 1 : 0
  //       setScrollYDetails({direction, progress})
  //     }),
  //   [y, yProgress]
  // )

  React.useEffect(() => {
    if (onProgressChange) {
      return yRelative.onChange(onProgressChange)
    }
  }, [yRelative, onProgressChange])

  React.useLayoutEffect(() => {
    // console.log('useScrollYProgress > useLayoutEffect')

    function updateOffsets() {
      if (ref.current) {
        const element = ref.current
        const triggerOffset = document.documentElement.clientHeight * trigger

        setElementBounds({
          top: element.offsetTop - triggerOffset,
          bottom: element.offsetTop + element.offsetHeight - triggerOffset,
        })
      }
    }

    // FIXME: Sections are created at different frames
    // and offsetTop value is changing after layoutEffect.
    // let delayedLayoutEffect = window.setTimeout(updateOffsets, 900)
    updateOffsets()

    const onResizeThrottled = debounce(updateOffsets, 250)
    window.addEventListener('resize', onResizeThrottled)

    return () => {
      // window.clearTimeout(delayedLayoutEffect)
      window.removeEventListener('resize', onResizeThrottled)
    }
  }, [ref, trigger])

  React.useEffect(() => {
    const triggerViewportScrollUpdate = new Event('resize')
    const debouncedTrigger = debounce(() => {
      window.dispatchEvent(triggerViewportScrollUpdate)
    }, 250)

    function onMutate(mutationsList) {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          debouncedTrigger()
        }
      }
    }
    const observer = new MutationObserver(onMutate)
    observer.observe(ref.current, {
      attributes: false,
      childList: true,
      subtree: true,
    })

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [ref])

  return {y, yRelative}
}

import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {duration, ease, scale, type} from 'core/theme'
import {ExternalLink} from 'elements'
import {ReactComponent as DownloadIcon} from 'modules/Article/assets/action-icons/Download.svg'

const StyledFigure = styled.figure`
  margin: 0;
  position: relative;

  a[download] {
    position: absolute;
    top: 0;
    right: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  @media (pointer: fine) {
    a[download] {
      opacity: 0;
      transition: opacity ${duration.normal.out} ${ease.out};

      &:hover {
        opacity: 1;
      }
    }

    img:hover ~ a[download] {
      opacity: 1;
      transition: opacity ${duration.normal.in} ${ease.in};
    }
  }

  figcaption {
    ${type.caption};
    margin-top: ${rem(scale[2])};
  }
`

export default function Figure({image, as, ...props}) {
  return (
    <StyledFigure {...props} as={as}>
      {image && (
        <>
          <img src={image.src} alt={image.alt || ''} />

          {image.download && (
            <ExternalLink
              title='Click to download full resolution image'
              url={image.download}
              download
            >
              <DownloadIcon />
            </ExternalLink>
          )}

          {image.caption && <figcaption>{image.caption}</figcaption>}
        </>
      )}
    </StyledFigure>
  )
}

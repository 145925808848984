import React from 'react'
import {useLocation} from 'react-router-dom'

export const defaultLang = 'en'
export const availableLangs = ['en', 'ru']

export const LanguageContext = React.createContext({
  defaultLang,
  availableLangs,
  activeLang: defaultLang,
  translations: {},
})

export function useTranslation() {
  const {activeLang, translations} = React.useContext(LanguageContext)

  const translate = React.useCallback(
    str => {
      if (translations.hasOwnProperty(activeLang)) {
        const activeTranslations = translations[activeLang]
        const stringsObj = activeTranslations[str]
        if (stringsObj) {
          return stringsObj
        } else {
          console.warn(`Translation for '${str}' does not exist.`)
        }
      }
      return str
    },
    [activeLang, translations]
  )

  // Use Regular Expression:
  // translate\(['"](.*)['"]\)
  // to find occurances in code

  return translate
}

export function LanguageProvider({children}) {
  const location = useLocation()
  const firstTwo = location.pathname.substr(1, 2)
  const detectedLang =
    availableLangs.find(lang => lang === firstTwo) || defaultLang

  const [language, setLanguage] = React.useState({
    defaultLang,
    availableLangs,
    activeLang: detectedLang,
    translations: {},
  })

  React.useEffect(() => {
    const lang = detectedLang
    if (!language.translations[lang] && lang !== defaultLang) {
      fetch(`/api/translations/${lang}`)
        .then(res =>
          res.ok
            ? res
            : Promise.reject({message: res.statusText, status: res.status})
        )
        .then(res => res.json())
        .then(data => {
          const newData = {
            defaultLang,
            availableLangs,
            activeLang: lang,
            translations: {...language.translations, [lang]: data},
          }
          setLanguage(newData)
        })
        .catch(error => {
          // Do nothing
        })
    } else if (language.activeLang !== lang) {
      const newData = {
        defaultLang,
        availableLangs,
        activeLang: lang,
        translations: {...language.translations},
      }
      setLanguage(newData)
    }
  }, [language.activeLang, language.translations, detectedLang])

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  )
}

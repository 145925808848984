import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {color, scale} from 'core/theme'

const StyledSelect = styled.select`
  border: 1px solid ${color.brand.deepBlue};
  color: ${color.brand.deepBlue};
  font: inherit;
  padding: ${rem(scale[1])};
  width: 100%;

  &:focus {
    outline: 0;
    border-color: ${color.brand.blue};
  }

  text-align: center;
  text-align-last: center;

  option {
    text-align: center;
    text-align-last: center;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACsSURBVHgB7ZLBDcIwEATXqSAlUIKhAzoIP8MH3EE6gA4o4fJCdEEHKKXQwXHgiAeyk/P/RooiRbs7sWTAMIwsmz318lzV+UCXUr7JfWTgzIxeioQF1oGGKd/pBQ5bOLykeJqTfMbldfxmG+xyGVcq+wN5x3jIcVoJDc97jMVx+aHxFscqwZxEO74oyEk4dVTjKsG/ZGqpxlNUyU+CdAk049X4QCvfUQvDMKp4A0neVUChorptAAAAAElFTkSuQmCC')
    right center no-repeat !important;
  background-position: calc(100% - 5px) center !important;
`

const Select = React.forwardRef(({children, ...props}, ref) => (
  <StyledSelect ref={ref} {...props}>
    {children}
  </StyledSelect>
))

export default Select

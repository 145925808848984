export const measurements = {
  contentWidth: 1170,
  siteWidth: 1440,
}

export const breakpoints = {
  mobile: 416,
  tablet: 800,
  laptop: 1024,
  laptopMedium: 1280,

  content: measurements.contentWidth,
  site: measurements.siteWidth,
}

export const scale = [0, 9, 18, 27, 36, 45, 54, 63, 72, 81, 90]

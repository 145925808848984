import React from 'react'
import styled, {css} from 'styled-components/macro'
import {rem, transparentize} from 'polished'
import {color, scale} from 'core/theme'

const StyledDetails = styled.details`
  summary {
    border-top: 1px solid ${transparentize(0.75, color.brand.deepBlue)};
    cursor: pointer;
    font-weight: 700;
    padding: ${rem(scale[2])};
    user-select: none;

    small {
      display: inline-block;
      margin-left: 1em;
      font-size: 0.65em;
      font-weight: 400;
    }

    &::-webkit-details-marker,
    &::marker {
      content: '';
      display: none;
    }

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF1SURBVHgB7dS7TQNBFIXhM6YCMkogIcCNkJoIrygAUwEQEiEqWDvCITRiOaAISiDysEcYyUJev2bm3pV1vmSkWWn26t/VACIiIiIiIiIiIiIiIiLHLyBRf1A/xIDR8rDx7K26h6Pc8yQF6l/XLzH+DrNy4Hg2rSo4aOLUERiu7kUsnubT20ccqIcE/4f52+OgMLYuDoXQu0OCpEBtrCO1xckh7Q+Ki9fWZ0aRtsWJC0yQIPmSvhzU42a5QfsLit1JW+MAk/m0GiJBciDyiGQRh7IEIstIVnEoWyCyiGQZh7IGopKRrONQ9kBUIpJHHCoSiHJG8opDxQJRjkiecahoIEqJ5B2HigeiQyJ1IQ5gFIj2idSVOMu57OwSiWtX4gDGgWhbpE2s45B5IDokkkcccglE+0TyikNugWiXSJ5xyDUQbYrkHYfcA9G6SF2IQyfogK/Pj/ezi6vTEMJ588m+mzjPTZwRRERERERERERERERERMTED2pf7ry107wcAAAAAElFTkSuQmCC');
    background-position: calc(100% - ${rem(scale[2])}) center;
    background-repeat: no-repeat;
    background-size: ${rem(24)};

    &:hover {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFpSURBVHgB7dnNTcNAFEXhZzqCVEBaQFkTKgAqACoAKiBZk7QQKggpafC1BEIozt/MvGeh8y0dybk6sizZNgMAAAAAAAAAAAD+v8YyLTfjh5TsrjtZY7Or89W9BSq9JytQO+b5e8zPCVM7arS6sQCLzfjNkk1/H2v3PU1Gq0c70ZllSH/GdMcamy7X7VBn2+JIexXdWoasQH28I/XFKSH3Cnrt/c0p0t44yeaWIfsm/f55OWusue79g4r3pH1xkqX55OJjahmyA0lEJI84UiSQeEbyiiPFAolHJM84UjSQ1IzkHUeKB5IakSLiSJVAUjJSVBypFkhKRIqMI1UDSU6k6DhSPZCcEmkIccQlkBwTaShxul3m6JBISU+HA4nTbTJn+yLt4h1H3APJKZEi4khIIDkmUlQcCQskh0SKjCOhgWRXpOg4Eh5ItkUaQhwZRCBZrMcv7Zoukl7l5nyJAAAAAAAAAAAAAHCcL1E2EYtlMOMfAAAAAElFTkSuQmCC');
      color: ${color.brand.green};
    }

    ${({disabled}) =>
      disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `};
  }

  summary + *:not(:empty) {
    padding: 0 ${rem(scale[2])};

    &:last-child {
      margin-bottom: ${rem(scale[3])};
    }
  }

  &:focus-within {
    summary {
      outline: 0;
    }
  }

  &[open] > summary {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGJSURBVHgB7dc/TsMwFIDx53ICtjKyMRSp9CDAWCaajY2ysTEzIU7QMlE24AScABh6iB6BqcFp9aRKJf3n2C9C30+qGsVREn1yLFkEAAAAAAAAAAAA+P+c1ESnO7jLnfSLY/9Sw8/n7EZqoBaBfJxBLtJbPDeLNMoyMWYe6K84qg6RTAOtiqOsI5kF2iSOsoxkEmibOMoqUvJAu8RRFpEaktC6OH7sSea/svFecQ9JKNkM2iTO9yibjZ90B0P/d1l2bcqZlCTQNnFUXSJFD7RLHFWHSFEDhcRR1pGiBaoijrKMFCVQlXGUVaTKA8WIoywiVRooZhyVOlJlgVLEUSkjVRIoZRyVKlJwIIs4KkWkPQnQuRg8+ABXZeMx4xQm47fXg9b5oT9sl1zSbrZO3WT8/iE7CtqsWs2cRV/zZ5RucJ1rXEuAKLv5VHHUukghwmZQPn1cOpc4jiqLlE/DwgWtQcW33Tw+23fOHfkV8cfHufdx+mKkWJOW3ucluxUAAAAAAAAAAAAAK/0CA07zOD/D0JUAAAAASUVORK5CYII=');

    &:hover {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFWSURBVHgB7djRTcMwEIDhMxtBJyAroD5TJgAmoBsAE1CeCyuECUpHMnGlk5BoSFvbdwb930siJ3KiX5GlWAQAAAAAAAAAAAD4/4I04n3bPcQod+k8BFldnff30oAmAr1tuxeJsvg+FuIQadbfiDP3QPviqBYiuQb6LY7yjuQW6JA4yjOSS6Bj4iivSOaBTomjPCKZBpqKEyW+pmOQcD12j3Uks0CHxJlffOyurz8vV61EMgl0TBzVSqTqgU6Jo1qIVDVQThzlHalaoBJxlGekKoFKxlFekYoHqhFHeUQqGqhmHGUdqVggizjKMlKRQJZxlFWk7EAecZRFpDPJMGyTPnrFSdLc+v+29/lBFutNt5QMWYGiYxw1FWnY376VDFmBxljFUVORcuR+Qc8/xozjqNFIUbLC5S/Sm+5pmGW3UKZg81m/FEetvQ8AAAAAAAAAAADwF3wBc6QTmOANFlAAAAAASUVORK5CYII=');
    }
  }

  ${({outlined}) =>
    outlined &&
    css`
      summary {
        border: 1px solid ${color.brand.deepBlue};
      }
    `};
`

export default function Details({
  summary,
  renderSummary,
  children,
  outlined,
  disabled,
  onToggle,
  defaultOpen = false,
}) {
  const [open, setOpen] = React.useState(defaultOpen)
  return (
    <StyledDetails
      outlined={outlined}
      open={open}
      disabled={disabled}
      onToggle={event => {
        const {open} = event.target
        onToggle && onToggle(open)
        setOpen(open)
      }}
    >
      {renderSummary ? (
        renderSummary(summary)
      ) : summary ? (
        <summary>{summary}</summary>
      ) : null}
      {children}
    </StyledDetails>
  )
}

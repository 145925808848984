import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {NavLink} from 'react-router-dom'
import {motion} from 'framer-motion'
import {color} from 'core/theme'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 9;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const List = styled(motion.ul)`
  justify-content: center;
`

const Item = styled(motion.li)`
  font-size: ${rem(34)};
  font-weight: 700;

  > a {
    color: inherit;
    text-decoration: none;

    &.active {
      color: ${color.text.green};
    }
  }
`

const WishlistLink = styled(NavLink)`
  display: flex;
  align-items: center;
  position: relative;

  > svg {
    margin-right: 0.5em;

    /* position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-100%); */
  }
`

const backgroundVariants = {
  open: ({height = 1000}) => ({
    backgroundColor: color.white,
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 30px) 30px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: ({color}) => ({
    backgroundColor: color,
    clipPath: 'circle(0px at calc(100% - 30px) 30px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 150,
      damping: 40,
    },
  }),
  exit: ({color}) => ({
    backgroundColor: color,
    clipPath: 'circle(0px at calc(100% - 30px) 30px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 150,
      damping: 40,
    },
  }),
}

const listVariants = {
  open: {
    transition: {staggerChildren: 0.05, delayChildren: 0.2},
  },
  closed: {
    transition: {staggerChildren: 0.03, staggerDirection: -1},
  },
}

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: {type: 'spring', damping: 15, stiffness: 150},
    },
  },
  closed: {
    y: 75,
    opacity: 0,
    transition: {
      y: {type: 'spring', damping: 15, stiffness: 150},
    },
  },
}

export default function MobileNav({
  color,
  pages,
  onNavigate,
  wishlistLink,
  translate,
}) {
  const [height, setHeight] = React.useState(0)

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  return (
    <Container ref={measuredRef}>
      {height > 0 ? (
        <>
          <Background
            initial='closed'
            animate='open'
            exit='exit'
            custom={{height, color: color.get()}}
            variants={backgroundVariants}
          />

          <nav>
            <List
              initial='closed'
              animate='open'
              exit='closed'
              variants={listVariants}
            >
              {pages.map(page => (
                <Item key={page.slug} variants={itemVariants}>
                  <NavLink to={page.url} onClick={onNavigate}>
                    {page.title}
                  </NavLink>
                </Item>
              ))}

              {wishlistLink && (
                <Item key='wishlist' variants={itemVariants}>
                  <WishlistLink to={wishlistLink} onClick={onNavigate}>
                    <svg
                      width='22'
                      height='20'
                      viewBox='0 0 22 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M20.9999 3.09297C20.1066 1.25964 17.5332 -0.24036 14.5399 0.632973C13.1096 1.04613 11.8616 1.93216 10.9999 3.14631C10.1382 1.93216 8.89027 1.04613 7.45991 0.632973C4.45991 -0.227027 1.89324 1.25964 0.999911 3.09297C-0.253422 5.65964 0.266578 8.54631 2.54658 11.673C4.33325 14.1196 6.88658 16.5996 10.5932 19.4796C10.7104 19.571 10.8547 19.6206 11.0032 19.6206C11.1518 19.6206 11.2961 19.571 11.4132 19.4796C15.1132 16.6063 17.6732 14.1463 19.4599 11.673C21.7332 8.54631 22.2532 5.65964 20.9999 3.09297Z'
                        fill='currentColor'
                      />
                    </svg>
                    {translate('Wishlist')}
                  </WishlistLink>
                </Item>
              )}
            </List>
          </nav>
        </>
      ) : null}
    </Container>
  )
}

import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {media} from 'core/theme'

const BabyBottle = React.lazy(() => import('./graphics/BabyBottleLazy'))
const Bottle = React.lazy(() => import('./graphics/BottleLazy'))
const Carton = React.lazy(() => import('./graphics/CartonLazy'))
const ChampionCup = React.lazy(() => import('./graphics/ChampionCupLazy'))
const Cheese = React.lazy(() => import('./graphics/CheeseLazy'))
const ChugGlass = React.lazy(() => import('./graphics/ChugGlassLazy'))
const ConvenienceFood = React.lazy(() =>
  import('./graphics/ConvenienceFoodLazy')
)
const Dessert = React.lazy(() => import('./graphics/DessertLazy'))
const DoubleCarton = React.lazy(() => import('./graphics/DoubleCartonLazy'))
const FrozenFood = React.lazy(() => import('./graphics/FrozenFoodLazy'))
const GlassesCheers = React.lazy(() => import('./graphics/GlassesCheersLazy'))
const Heart = React.lazy(() => import('./graphics/HeartLazy'))
const IceCream = React.lazy(() => import('./graphics/IceCreamLazy'))
const Ingredients = React.lazy(() => import('./graphics/IngredientsLazy'))
const Medal = React.lazy(() => import('./graphics/MedalLazy'))
const Microscope = React.lazy(() => import('./graphics/MicroscopeLazy'))
const MilkCan = React.lazy(() => import('./graphics/MilkCanLazy'))
const MilkPowder = React.lazy(() => import('./graphics/MilkPowderLazy'))
const Milkshake = React.lazy(() => import('./graphics/MilkshakeLazy'))
const Planet = React.lazy(() => import('./graphics/PlanetLazy'))
const Plant = React.lazy(() => import('./graphics/PlantLazy'))
const SweetMilk = React.lazy(() => import('./graphics/SweetMilkLazy'))
const Treasure = React.lazy(() => import('./graphics/TreasureLazy'))
const Yoghurt = React.lazy(() => import('./graphics/YoghurtLazy'))

const sizes = [100, 190]

const Placeholder = styled.div`
  width: ${rem(sizes[0])};
  height: ${rem(sizes[0])};

  ${media.tablet`
    width: ${rem(sizes[1])};
    height: ${rem(sizes[1])};
  `};
`

export default function Graphic({icon}) {
  const iconComponent = React.useMemo(() => {
    switch (icon) {
      case 'baby-bottle':
        return <BabyBottle />
      case 'bottle':
        return <Bottle />
      case 'carton':
        return <Carton />
      case 'champion-cup':
        return <ChampionCup />
      case 'cheese':
        return <Cheese />
      case 'chug-glass':
        return <ChugGlass />
      case 'convenience-food':
        return <ConvenienceFood />
      case 'dessert':
        return <Dessert />
      case 'double-carton':
        return <DoubleCarton />
      case 'frozen-food':
        return <FrozenFood />
      case 'glasses-cheers':
        return <GlassesCheers />
      case 'heart':
        return <Heart />
      case 'ice-cream':
        return <IceCream />
      case 'ingredients':
        return <Ingredients />
      case 'medal':
        return <Medal />
      case 'microscope':
        return <Microscope />
      case 'milk-can':
        return <MilkCan />
      case 'milk-powder':
        return <MilkPowder />
      case 'milkshake':
        return <Milkshake />
      case 'planet':
        return <Planet />
      case 'plant':
        return <Plant />
      case 'sweet-milk':
        return <SweetMilk />
      case 'treasure':
        return <Treasure />
      case 'yoghurt':
        return <Yoghurt />

      default:
        return null
    }
  }, [icon])

  return (
    <React.Suspense fallback={<Placeholder />}>{iconComponent}</React.Suspense>
  )
}

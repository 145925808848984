import React from 'react'
import debounce from 'lodash.debounce'

export default function useMatchMedia(minWidth, {once} = {}) {
  function matchBreapoint(minWidth) {
    const breakpoint = window.matchMedia(`(min-width: ${minWidth / 16}rem)`)
    return breakpoint.matches
  }

  const [breakpointMatches, setBreakpointMatches] = React.useState(() =>
    matchBreapoint(minWidth)
  )

  React.useEffect(() => {
    if (!once) {
      function onResize() {
        setBreakpointMatches(matchBreapoint(minWidth))
      }

      const onResizeThrottled = debounce(onResize, 250)
      window.addEventListener('resize', onResizeThrottled)
      onResize()

      return () => {
        window.removeEventListener('resize', onResizeThrottled)
      }
    }
  }, [minWidth, once])

  return breakpointMatches
}

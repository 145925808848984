import React from 'react'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {color, duration, ease, media, scale} from 'core/theme'

const size = [140, 220]

const Circle = styled.div`
  background-color: ${color.white};
  border: 2px solid rgba(49, 88, 159, 0.04);
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(12, 30, 77, 0.04);

  width: ${rem(size[0])};
  height: ${rem(size[0])};

  ${media.tablet`
    width: ${rem(size[1])};
    height: ${rem(size[1])};
  `};
`

const MutedRect = styled.div`
  background-color: ${color.white};
  border: 2px solid transparent;

  width: ${rem(size[0])};
  height: ${rem(size[0])};

  ${media.tablet`
    width: ${rem(size[1])};
    height: ${rem(size[1])};
  `};
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
`

const Label = styled.h2`
  font-size: ${rem(15)};
  font-weight: 700;
  line-height: 150%;
  margin-top: ${rem(scale[1])};
  margin-bottom: 0;

  text-align: center;

  > a::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ${media.tablet`
    font-size: ${rem(20)};
    margin-top: ${rem(scale[2])};
  `};

  ${({muted}) =>
    muted &&
    css`
      font-weight: 400;
      line-height: 120%;
    `};
`

const Wrapper = styled(motion.div)`
  position: relative;
  width: ${rem(size[0])};

  a {
    color: inherit;
    display: block;
    text-decoration: none;
  }

  ${({interactive}) =>
    interactive &&
    css`
      cursor: pointer;

      ${Circle} {
        transition: box-shadow ${duration.normal.in} ${ease.in};
      }

      &:hover ${Circle} {
        box-shadow: 0px 4px 8px rgba(12, 30, 77, 0.12);
        transition: box-shadow ${duration.normal.out} ${ease.out};
      }
    `};

  ${media.tablet`
    width: ${rem(size[1])};
  `};
`

const spring = {
  scale: 1.1,
  transition: {type: 'spring', stiffness: 300, damping: 15},
}

export default function ItemImage(props) {
  const {
    image,
    label,
    link,
    title,
    // products
    appearance,
  } = props

  const motion = {
    initial: props.initial,
    animate: props.animate,
    variants: props.variants,
  }

  const isMuted = appearance === 'muted'
  const ImageWrapper = isMuted ? MutedRect : Circle
  const imageEl =
    image && image.src ? <Image src={image.src} srcSet={image.srcSet} sizes='216px' alt='' title={title} /> : null

  if (label && link) {
    return (
      <Wrapper
        interactive={true}
        {...motion}
        /* FIXME: Drop-shadow spring integration + scale <ImageWrapper> only */
        whileHover={spring}
      >
        <ImageWrapper>{imageEl}</ImageWrapper>
        <Label muted={isMuted}>
          <Link to={link}>{label}</Link>
        </Label>
      </Wrapper>
    )
  }

  if (link) {
    return (
      <Wrapper
        interactive={true}
        {...motion}
        /* FIXME: Drop-shadow spring integration + scale <ImageWrapper> only */
        whileHover={spring}
      >
        <Link to={link}>
          <ImageWrapper>{imageEl}</ImageWrapper>
        </Link>
      </Wrapper>
    )
  }

  if (label) {
    return (
      <Wrapper {...motion}>
        <ImageWrapper>{imageEl}</ImageWrapper>
        <Label muted={isMuted}>{label}</Label>
      </Wrapper>
    )
  }

  return (
    <Wrapper {...motion}>
      <ImageWrapper>{imageEl}</ImageWrapper>
    </Wrapper>
  )
}

import React from 'react'
import styled, {css} from 'styled-components/macro'
import {rem} from 'polished'
import {color, media, scale} from 'core/theme'
import Graphic from './Graphic'

const sizes = [100, 190]

const getColorByIndex = index => {
  switch (index % 4) {
    case 1:
      return color.brand.yellow
    case 2:
      return color.brand.blue
    case 3:
      return color.brand.purple
    default:
      return color.brand.green
  }
}

const Wrapper = styled.div`
  box-sizing: content-box;

  svg {
    margin: auto;
    width: ${rem(sizes[0])};
    height: ${rem(sizes[0])};

    path {
      stroke: ${color.white};
    }

    circle {
      stroke: ${color.white};
      stroke-opacity: 0.2;
      stroke-width: 2px;
    }
  }

  ${media.tablet`
    padding: 0 ${rem(scale[2])};

    svg {
      width: ${rem(sizes[1])};
      height: ${rem(sizes[1])};
    }
  `};

  ${({custom}) =>
    custom &&
    css`
      svg circle {
        fill: ${getColorByIndex(custom.index)};
      }
    `};

  ${({large}) =>
    large &&
    css`
      width: ${rem(170)};
    `};
`

const Label = styled.h5`
  font-size: ${rem(18)};
  margin-top: ${rem(scale[1])};
  margin-bottom: 0;
  text-align: center;

  ${media.tablet`
    font-size: ${rem(20)};
    margin-top: ${rem(scale[3])};
  `};
`

const Text = styled.p`
  font-size: ${rem(15)};
  margin-top: ${rem(scale[1])};
  margin-bottom: 0;
  text-align: center;

  ${media.tablet`
    font-size: ${rem(18)};
    margin-top: ${rem(scale[3])};
  `};
`

export default function CircleGraphic(props) {
  const {icon, label, text, custom, large} = props
  return (
    <Wrapper custom={custom} large={large}>
      <Graphic icon={icon} />
      {label && <Label>{label}</Label>}
      {text && <Text>{text}</Text>}
    </Wrapper>
  )
}

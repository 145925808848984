import React from 'react'
import styled from 'styled-components/macro'
import {motion, useTransform} from 'framer-motion'
import {ColorPresence} from 'core/theme'
import {ReactComponent as ColoredLogo} from '../assets/FU-logo-color.svg'
import {ReactComponent as MonochromeLogo} from '../assets/FU-logo-monochrome.svg'

const LogoWrapper = styled.div`
  position: relative;

  > *:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export default function Logo() {
  const {saturation} = React.useContext(ColorPresence)
  const saturationInverted = useTransform(saturation, [0, 1], [1, 0])

  return (
    <LogoWrapper>
      <motion.div style={{opacity: saturation}}>
        <MonochromeLogo />
      </motion.div>
      <motion.div style={{opacity: saturationInverted}}>
        <ColoredLogo />
      </motion.div>
    </LogoWrapper>
  )
}
